import React, { useState, useEffect, useCallback } from "react";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "../../ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "../../ui/select";
import { Textarea } from "../../ui/textarea";
import {
  fetchPatients,
  registerPatient,
  readmitPatient,
  searchPatients,
} from "../../../redux/slices/patientSlice";
import { fetchRooms } from "../../../redux/slices/roomSlice";
import {
  initialFormData,
  validateForm,
  formatSubmissionData,
} from "./ipdRegHelpers";
import { useToast } from "../../../hooks/use-toast";
import { Loader2, Info, Search } from "lucide-react";
import MemoizedInput from "./MemoizedInput";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { fetchBills } from "../../../redux/slices/BillingSlice";
import { useMediaQuery } from "../../../hooks/use-media-query";
import SelectServicesDialog from "./SelectServicesDialog";
import { fetchServices } from "../../../redux/slices/serviceSlice";
import { fetchTemplates } from "../../../redux/slices/templatesSlice";
import BillModal from "./BillModal";
import { fetchHospitalInfo } from "../../../redux/slices/HospitalSlice";

export default function IPDRegDialog({ open, onOpenChange, patientData }) {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const registerPatientStatus = useSelector(
    (state) => state.patients.registerPatientStatus
  );
  console.log(patientData);
  const departments = useSelector((state) => state.departments.departments);
  const rooms = useSelector((state) => state.rooms.rooms);
  const doctors = useSelector((state) => state.staff.doctors);
  const hospitalInfo = useSelector((state) => state.hospital.hospitalInfo);
  const hospitalInfoStatus = useSelector(
    (state) => state.hospital.hospitalInfoStatus
  );

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [isSelectServicesDialogOpen, setIsSelectServicesDialogOpen] =
    useState(false);
  const { services, servicesStatus } = useSelector((state) => state.services);
  const { serviceBillCollections, status } = useSelector(
    (state) => state.templates
  );
  const [totalAmount, setTotalAmount] = useState(0);
  const [showBillModal, setShowBillModal] = useState(false);
  const [billData, setBillData] = useState(null);
  const [roomCharge, setRoomCharge] = useState(0);
  const [searchedPatient, setSearchedPatient] = useState(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTemplates());
    }
    if (servicesStatus === "idle") {
      dispatch(fetchServices());
    }
  }, [dispatch, status, servicesStatus]);

  useEffect(() => {
    // Only calculate room charge when room is selected
    if (formData.admission.assignedRoom) {
      const selectedRoom = rooms.find(
        (room) => room._id === formData.admission.assignedRoom
      );
      if (selectedRoom) {
        setRoomCharge(selectedRoom.ratePerDay || 0);
      } else {
        setRoomCharge(0);
      }
    }
  }, [formData.admission.assignedRoom, rooms]);

  useEffect(() => {
    // Calculate total using services plus room charge
    const servicesTotal = services
      .filter((service) => formData.paymentInfo.services.includes(service._id))
      .reduce((sum, service) => sum + (service.rate || 0), 0);

    const totalWithRoom = servicesTotal + roomCharge;

    setFormData((prev) => ({
      ...prev,
      paymentInfo: {
        ...prev.paymentInfo,
        totalAmount: totalWithRoom,
      },
    }));
    setTotalAmount(totalWithRoom);
  }, [formData.paymentInfo.services, services, roomCharge]);

  useEffect(() => {
    if (hospitalInfoStatus === "idle") {
      dispatch(fetchHospitalInfo());
    }
  }, [dispatch, hospitalInfoStatus]);

  // Function to reset form data
  const resetFormData = useCallback(() => {
    if (!open) {
      setFormData(initialFormData);
      setErrors({});
      setTotalAmount(0);
      setRoomCharge(0);
      setSearchedPatient(null);
    }
  }, [open]);

  useEffect(() => {
    if (patientData || searchedPatient) {
      const sourceData = searchedPatient || patientData;
      setFormData((prev) => ({
        ...prev,
        _id: sourceData._id,
        name: sourceData.name || "",
        age: sourceData.age || "",
        gender: sourceData.gender || "",
        contactNumber: sourceData.contactNumber || "",
        email: sourceData.email || "",
        address: sourceData.address || "",
        registrationNumber: sourceData.registrationNumber || "",
        dateOfBirth: sourceData.dateOfBirth || "",
        bloodType: sourceData.bloodType || "",
        // Keep existing admission and payment info
        admission: {
          ...prev.admission,
        },
        paymentInfo: {
          ...prev.paymentInfo,
        },
      }));
    }
  }, [patientData, searchedPatient, open]);

  useEffect(() => {
    if (!open) {
      dispatch(fetchRooms());
      resetFormData();
      setTotalAmount(0); // Reset total amount
      setRoomCharge(0); // Reset room charge
      setTimeout(() => {
        document.body.style = "";
      }, 500);
      setSearchedPatient(null);
    }
  }, [open, resetFormData, dispatch]);

  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prev) => {
      const keys = id.split(".");
      const newState = { ...prev };
      let current = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = { ...current[keys[i]] };
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = type === "checkbox" ? checked : value;

      // If the changed field is the assigned room, update the room charge
      if (id === "admission.assignedRoom") {
        const selectedRoom = rooms.find((room) => room._id === value);
        if (selectedRoom) {
          setRoomCharge(selectedRoom.ratePerDay || 0);
        } else {
          setRoomCharge(0);
        }
      }

      return newState;
    });
  };

  const handleDobChange = useCallback(
    (e) => {
      const dateOfBirth = e.target.value;
      const age = dateOfBirth
        ? new Date().getFullYear() - new Date(dateOfBirth).getFullYear()
        : "";
      handleInputChange({ target: { id: "dateOfBirth", value: dateOfBirth } });
      handleInputChange({ target: { id: "age", value: age.toString() } });
    },
    [handleInputChange]
  );

  const handleAgeChange = useCallback(
    (e) => {
      const age = e.target.value;
      handleInputChange({ target: { id: "age", value: age } });
      handleInputChange({ target: { id: "dateOfBirth", value: "" } });
    },
    [handleInputChange]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm(formData, setErrors)) {
      const submissionData = formatSubmissionData(formData);
      if (patientData || searchedPatient) {
        // This is a readmission
        dispatch(
          readmitPatient({
            patientId: patientData._id,
            admission: submissionData,
          })
        )
          .unwrap()
          .then((result) => {
            toast({
              title: "Patient admitted successfully",
              description: "The patient has been admitted.",
              variant: "success",
            });
            dispatch(fetchPatients());
            dispatch(fetchRooms());
            dispatch(fetchBills());
            setBillData(result.bill);
            setShowBillModal(true);
          })
          .catch((error) => {
            toast({
              title: "Failed to admit patient",
              description:
                error.message ||
                "There was an error admitting the patient. Please try again.",
              variant: "destructive",
            });
          })
          .finally(() => {
            onOpenChange(false);
          });
      } else {
        // This is a new patient registration
        dispatch(registerPatient(submissionData))
          .unwrap()
          .then((result) => {
            toast({
              title: "Patient registered successfully",
              description: "The new patient has been added.",
              variant: "success",
            });
            onOpenChange(false);
            dispatch(fetchPatients());
            dispatch(fetchRooms());
            dispatch(fetchBills());
            setBillData(result.bill);
            setShowBillModal(true);
          })
          .catch((error) => {
            toast({
              title: "Failed to register patient",
              description:
                error.message ||
                "There was an error registering the patient. Please try again.",
              variant: "destructive",
            });
          })
          .finally(() => {});
      }
    }
  };

  const handleDialogClose = () => {
    onOpenChange(false);
    resetFormData();
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setErrors({});
    setTotalAmount(0); // Reset total amount
    setRoomCharge(0); // Reset room charge
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        document.body.style = "";
      }, 500);
    }
  }, [open]);
  console.log(formData.paymentInfo.totalAmount);
  const handleInfoClick = (e) => {
    e.preventDefault();
    setIsSelectServicesDialogOpen(true);
  };

  const handleServicesChange = (selectedServices) => {
    const actualServices = selectedServices.filter(
      (id) => id !== "room-charge"
    );
    setFormData((prevData) => ({
      ...prevData,
      paymentInfo: {
        ...prevData.paymentInfo,
        services: actualServices,
      },
    }));
  };

  // Add this function to get all services including room for display
  const getDisplayServices = useCallback(() => {
    // Get the selected room service if any
    const selectedRoom = rooms.find(
      (room) => room._id === formData.admission.assignedRoom
    );
    const roomService = selectedRoom
      ? {
          _id: "room-charge",
          name: `Room: ${selectedRoom.roomNumber} - ${selectedRoom.type}`,
          rate: selectedRoom.ratePerDay || 0,
          isRoom: true,
        }
      : null;

    // Get all available services from the services array
    const availableServices = services.map((service) => ({
      ...service,
      isRoom: false,
    }));

    // Combine room service (if exists) with available services
    return roomService
      ? [roomService, ...availableServices]
      : availableServices;
  }, [formData.admission.assignedRoom, rooms, services]);

  const handleSearch = async () => {
    if (!formData.registrationNumber) return;

    try {
      const result = await dispatch(
        searchPatients(formData.registrationNumber)
      ).unwrap();
      if (result.results && result.results.length > 0) {
        const patient = result.results[0];
        setSearchedPatient({
          ...patient,
          isFromSearch: true,
        });
      }
    } catch (error) {
      console.error("Search failed:", error);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleDialogClose}>
        <DialogContent
          className={` ${
            isMobile ? "w-[95vw] p-4 rounded-lg gap-0 " : "max-w-[1000px]"
          } h-[${isMobile ? "70vh" : "60vh"}] overflow-y-auto`}
        >
          <DialogHeader className="mb-4 md:mb-0">
            <DialogTitle>
              {patientData ? "Admit IPD Patient" : "Register New IPD Patient"}
            </DialogTitle>
            <DialogDescription className={isMobile ? "hidden" : ""}>
              {patientData
                ? "Fill details for patient Admission"
                : "Fill basic details of patient for new IPD registration"}
            </DialogDescription>
          </DialogHeader>
          <form
            onSubmit={handleSubmit}
            className={`space-y-4 h-[calc(${
              isMobile ? "70vh" : "60vh"
            }-115px)]`}
          >
            <Tabs defaultValue="basic-info" className="w-full">
              <TabsList
                className={`grid w-full ${
                  isMobile ? "grid-cols-3" : "grid-cols-3"
                }`}
              >
                <TabsTrigger value="basic-info">
                  {isMobile ? "Basic" : "Basic Information"}
                </TabsTrigger>
                <TabsTrigger value="vitals">Vitals</TabsTrigger>
                <TabsTrigger value="insurance">Insurance</TabsTrigger>
              </TabsList>

              <TabsContent value="basic-info">
                <div
                  className={`grid ${
                    isMobile ? "grid-cols-1" : "grid-cols-3"
                  } mt-4 gap-4`}
                >
                  <div className="space-y-4">
                    <MemoizedInput
                      id="name"
                      label="Full Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      error={errors.name}
                    />
                    {isMobile ? (
                      <div className="grid grid-cols-2 gap-2">
                        <MemoizedInput
                          id="age"
                          label="Age"
                          type="number"
                          value={formData.age}
                          onChange={handleAgeChange}
                          error={errors.age}
                        />
                        <div className="relative">
                          <MemoizedInput
                            id="registrationNumber"
                            label="Reg Number"
                            value={formData.registrationNumber}
                            onChange={handleInputChange}
                            className="pr-10"
                          />
                          <button
                            type="button"
                            onClick={handleSearch}
                            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                          >
                            <Search className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="relative">
                          <MemoizedInput
                            id="registrationNumber"
                            label="Registration Number"
                            value={formData.registrationNumber}
                            onChange={handleInputChange}
                            className="pr-10"
                          />
                          <button
                            type="button"
                            onClick={handleSearch}
                            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                          >
                            <Search className="h-5 w-5" />
                          </button>
                        </div>
                        <div className="flex items-end gap-4">
                          <div className="w-30 relative">
                            <MemoizedInput
                              id="age"
                              label="Age"
                              type="number"
                              value={formData.age}
                              onChange={handleAgeChange}
                              error={errors.age}
                            />
                          </div>
                          <div className="flex-grow relative">
                            <MemoizedInput
                              id="dateOfBirth"
                              label="Date of Birth"
                              type="date"
                              value={formData.dateOfBirth}
                              onChange={handleDobChange}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="space-y-4">
                    <div>
                      <MemoizedInput
                        id="contactNumber"
                        label="Phone"
                        type="tel"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        error={errors.contactNumber}
                      />
                    </div>
                    {/* {!isMobile && (
                      <div>
                        <MemoizedInput
                          id="email"
                          label="Email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    )} */}
                    <div className={`space-y-4`}>
                      <Textarea
                        id="address"
                        placeholder="Address"
                        value={formData.address}
                        onChange={handleInputChange}
                        className="min-h-[90px]"
                      />
                      {/* Blood group selection commented out
                      <Select
                        id="bloodType"
                        onValueChange={(value) =>
                          handleInputChange({
                            target: { id: "bloodType", value },
                          })
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Blood Group" />
                        </SelectTrigger>
                        <SelectContent>
                          {["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"].map(
                            (type) => (
                              <SelectItem key={type} value={type}>
                                {type}
                              </SelectItem>
                            )
                          )}
                        </SelectContent>
                      </Select>
                      */}
                    </div>
                  </div>

                  <div className="space-y-4">
                    {isMobile ? (
                      <>
                        <div className={`grid grid-cols-2 gap-2`}>
                          <Select
                            id="admission.department"
                            onValueChange={(value) =>
                              handleInputChange({
                                target: { id: "admission.department", value },
                              })
                            }
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Department" />
                            </SelectTrigger>
                            <SelectContent>
                              {departments.map((dept) => (
                                <SelectItem key={dept._id} value={dept._id}>
                                  {dept.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>

                          <Select
                            id="admission.assignedDoctor"
                            onValueChange={(value) =>
                              handleInputChange({
                                target: {
                                  id: "admission.assignedDoctor",
                                  value,
                                },
                              })
                            }
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Assigned Doctor" />
                            </SelectTrigger>
                            <SelectContent>
                              {doctors.map((doctor) => (
                                <SelectItem key={doctor._id} value={doctor._id}>
                                  {doctor.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                      </>
                    ) : (
                      <>
                        <Select
                          id="admission.department"
                          onValueChange={(value) =>
                            handleInputChange({
                              target: { id: "admission.department", value },
                            })
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Department" />
                          </SelectTrigger>
                          <SelectContent>
                            {departments.map((dept) => (
                              <SelectItem key={dept._id} value={dept._id}>
                                {dept.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <Select
                          id="admission.assignedDoctor"
                          onValueChange={(value) =>
                            handleInputChange({
                              target: { id: "admission.assignedDoctor", value },
                            })
                          }
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Assigned Doctor" />
                          </SelectTrigger>
                          <SelectContent>
                            {doctors.map((doctor) => (
                              <SelectItem key={doctor._id} value={doctor._id}>
                                {doctor.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </>
                    )}
                    {errors["admission.assignedDoctor"] && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors["admission.assignedDoctor"]}
                      </p>
                    )}

                    <div className={`grid grid-cols-2 gap-2`}>
                      <Select
                        id="admission.assignedRoom"
                        onValueChange={(value) => {
                          handleInputChange({
                            target: { id: "admission.assignedRoom", value },
                          });
                          setFormData((prev) => ({
                            ...prev,
                            admission: {
                              ...prev.admission,
                              assignedBed: "",
                            },
                          }));
                        }}
                      >
                        <SelectTrigger
                          className={
                            errors["admission.assignedRoom"]
                              ? "border-red-500"
                              : ""
                          }
                        >
                          <SelectValue placeholder="Room" />
                        </SelectTrigger>
                        <SelectContent>
                          {rooms
                            .filter((room) => room.status !== "Occupied")
                            .map((room) => (
                              <SelectItem key={room._id} value={room._id}>
                                {room.roomNumber} - {room.type}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                      <Select
                        id="admission.assignedBed"
                        onValueChange={(value) =>
                          handleInputChange({
                            target: { id: "admission.assignedBed", value },
                          })
                        }
                        disabled={!formData.admission.assignedRoom}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Bed" />
                        </SelectTrigger>
                        <SelectContent>
                          {formData.admission.assignedRoom &&
                            rooms
                              .find(
                                (room) =>
                                  room._id === formData.admission.assignedRoom
                              )
                              ?.beds.filter((bed) => bed.status !== "Occupied")
                              .map((bed) => (
                                <SelectItem key={bed._id} value={bed._id}>
                                  {bed.bedNumber}
                                </SelectItem>
                              ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  <div>
                    <Select
                      id="gender"
                      value={formData.gender}
                      onValueChange={(value) =>
                        handleInputChange({ target: { id: "gender", value } })
                      }
                    >
                      <SelectTrigger
                        className={errors.gender ? "border-red-500" : ""}
                      >
                        <SelectValue placeholder="Gender" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Male">Male</SelectItem>
                        <SelectItem value="Female">Female</SelectItem>
                        <SelectItem value="Other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                    {errors.gender && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.gender}
                      </p>
                    )}
                  </div>

                  {/* Bill Details Section */}
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span>Final Bill:</span>
                        <Input
                          value={formData.paymentInfo.totalAmount.toLocaleString(
                            "en-IN"
                          )}
                          className="font-semibold w-28 inline-block"
                          onChange={(e) => {
                            const value = Number(
                              e.target.value.replace(/,/g, "")
                            );
                            if (!isNaN(value)) {
                              const servicesTotal = services
                                .filter((service) =>
                                  formData.paymentInfo.services.includes(
                                    service._id
                                  )
                                )
                                .reduce(
                                  (sum, service) => sum + (service.rate || 0),
                                  0
                                );

                              const totalWithRoom = servicesTotal + roomCharge;

                              setFormData((prev) => ({
                                ...prev,
                                paymentInfo: {
                                  ...prev.paymentInfo,
                                  totalAmount: value,
                                  additionalDiscount: Math.max(
                                    0,
                                    totalWithRoom - value
                                  ),
                                },
                              }));
                            }
                          }}
                        />
                      </div>
                      <Button
                        variant="outline"
                        className="border-primary text-primary"
                        onClick={handleInfoClick}
                      >
                        Modify Bill
                      </Button>
                    </div>
                    {formData.paymentInfo.additionalDiscount > 0 && (
                      <>
                        <p className="text-sm text-gray-500">
                          Services Total: ₹
                          {(
                            services
                              .filter((service) =>
                                formData.paymentInfo.services.includes(
                                  service._id
                                )
                              )
                              .reduce(
                                (sum, service) => sum + (service.rate || 0),
                                0
                              ) + roomCharge
                          ).toLocaleString("en-IN")}
                        </p>
                        {roomCharge > 0 && (
                          <p className="text-sm text-gray-500">
                            Room Charge: ₹{roomCharge.toLocaleString("en-IN")}
                          </p>
                        )}
                        <p className="text-sm text-gray-500">
                          Discount Applied: ₹
                          {formData.paymentInfo.additionalDiscount.toLocaleString(
                            "en-IN"
                          )}
                        </p>
                      </>
                    )}
                  </div>

                  {/* Payment Fields */}
                  <div className="grid grid-cols-2  gap-2">
                    <div>
                      <MemoizedInput
                        id="paymentInfo.amountPaid"
                        label="Amount Paid"
                        type="number"
                        value={formData.paymentInfo.amountPaid}
                        onChange={handleInputChange}
                      />
                    </div>
                    <Select
                      id="paymentInfo.paymentMethod"
                      value={formData.paymentInfo.paymentMethod}
                      onValueChange={(value) =>
                        handleInputChange({
                          target: { id: "paymentInfo.paymentMethod", value },
                        })
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Payment Method" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Cash">Cash</SelectItem>
                        <SelectItem value="UPI">UPI</SelectItem>
                        <SelectItem value="Card">Card</SelectItem>
                        <SelectItem value="Insurance">Insurance</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="vitals">
                <div className="space-y-4">
                  <div
                    className={`grid ${
                      isMobile ? "grid-cols-1" : "grid-cols-2"
                    } gap-4`}
                  >
                    <Textarea
                      id="admission.diagnosis"
                      placeholder="Diagnosis"
                      value={formData.admission.diagnosis}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            id: "admission.diagnosis",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                    {errors["admission.diagnosis"] && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors["admission.diagnosis"]}
                      </p>
                    )}

                    <Textarea
                      id="admission.conditionOnAdmission"
                      placeholder="Condition on Admission"
                      value={formData.admission.conditionOnAdmission}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            id: "admission.conditionOnAdmission",
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  </div>

                  <h4 className="font-semibold text-sm mt-4">
                    Admission Vitals
                  </h4>
                  <div
                    className={`grid ${
                      isMobile ? "grid-cols-2" : "grid-cols-3"
                    } gap-4`}
                  >
                    <Input
                      id="admission.vitals.admission.weight"
                      placeholder="Weight"
                      value={formData.admission.vitals.admission.weight}
                      onChange={handleInputChange}
                    />
                    <Input
                      id="admission.vitals.admission.height"
                      placeholder="Height"
                      value={formData.admission.vitals.admission.height}
                      onChange={handleInputChange}
                    />
                    <Input
                      id="admission.vitals.admission.bloodPressure"
                      placeholder="Blood Pressure"
                      value={formData.admission.vitals.admission.bloodPressure}
                      onChange={handleInputChange}
                    />
                    <Input
                      id="admission.vitals.admission.heartRate"
                      placeholder="Heart Rate"
                      value={formData.admission.vitals.admission.heartRate}
                      onChange={handleInputChange}
                    />
                    <Input
                      id="admission.vitals.admission.temperature"
                      placeholder="Temperature"
                      value={formData.admission.vitals.admission.temperature}
                      onChange={handleInputChange}
                    />
                    <Input
                      id="admission.vitals.admission.oxygenSaturation"
                      placeholder="Oxygen Saturation"
                      value={
                        formData.admission.vitals.admission.oxygenSaturation
                      }
                      onChange={handleInputChange}
                    />
                    <Input
                      id="admission.vitals.admission.respiratoryRate"
                      placeholder="Respiratory Rate"
                      value={
                        formData.admission.vitals.admission.respiratoryRate
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </TabsContent>

              <TabsContent value="insurance">
                <div className="mt-4 grid grid-cols-2 gap-4">
                  <MemoizedInput
                    id="admission.insuranceDetails.provider"
                    label="Insurance Provider"
                    value={formData.admission.insuranceDetails.provider}
                    onChange={handleInputChange}
                  />
                  <MemoizedInput
                    id="admission.insuranceDetails.policyNumber"
                    label="Policy Number"
                    value={formData.admission.insuranceDetails.policyNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </TabsContent>
            </Tabs>

            <DialogFooter className={`mt-4 ${isMobile ? "mb-8" : ""}`}>
              <div
                className={`w-full flex ${
                  isMobile ? "flex-col-reverse" : "flex-row"
                } justify-between sm:justify-end sm:space-x-2 space-y-2 sm:space-y-0`}
              >
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleReset}
                  className={`${isMobile ? "w-full mt-2" : ""}`}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  disabled={registerPatientStatus === "loading"}
                  className={`${isMobile ? "w-full" : ""}`}
                >
                  {registerPatientStatus === "loading" ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {patientData ? "Readmitting..." : "Registering..."}
                    </>
                  ) : patientData ? (
                    "Readmit Patient"
                  ) : (
                    "Register Patient"
                  )}
                </Button>
              </div>
            </DialogFooter>
          </form>
          <SelectServicesDialog
            isOpen={isSelectServicesDialogOpen}
            onClose={() => setIsSelectServicesDialogOpen(false)}
            services={getDisplayServices()}
            selectedServices={[
              ...formData.paymentInfo.services,
              formData.admission.assignedRoom ? "room-charge" : "",
            ].filter(Boolean)} // Filter out empty strings
            onServicesChange={handleServicesChange}
          />
        </DialogContent>
      </Dialog>
      {showBillModal && hospitalInfo && (
        <BillModal
          isOpen={showBillModal}
          onClose={() => setShowBillModal(false)}
          billData={billData}
          hospitalInfo={hospitalInfo}
        />
      )}
    </>
  );
}
